import { API_URL } from "./DataProvider";

const SESSION_KEY_AUTH = "auth";

const AuthProvider = {
  logout: async () => sessionStorage.removeItem(SESSION_KEY_AUTH),
  login: ({ username, password }) => {
    const request = new Request(API_URL + "/auth", {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        username,
        password
      })
    });

    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          sessionStorage.removeItem(SESSION_KEY_AUTH);
        }

        return response.json();
      })
      .then((data) => {
        if (typeof data.token !== 'undefined') {
          sessionStorage.setItem(SESSION_KEY_AUTH, JSON.stringify(data));
        } else {
          sessionStorage.removeItem(SESSION_KEY_AUTH);
        }
      })
      .catch(() => {
        throw new Error('Network error')
      });
  },
  checkAuth: () => sessionStorage.getItem(SESSION_KEY_AUTH) !== null
    ? Promise.resolve()
    : Promise.reject(), // react-admin passes the error message to the translation layer
  checkError: (error) => {
    if (!error || (!('status' in error)) || error.status === 401 || error.status === 403) {
      return Promise.reject();
    }

    return Promise.resolve();
  },
  getPermissions: async () => null,
  getIdentity: () => {
    try {
      const auth = sessionStorage.getItem(SESSION_KEY_AUTH);

      if (auth === null) {
        return Promise.reject(new Error(""));
      }

      return Promise.resolve({ id: 1, ...JSON.parse(auth)  });
    } catch (error) {
      return Promise.reject(error);
    }
  }
};

export default AuthProvider;