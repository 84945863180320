import * as React from "react";
import { Datagrid, TextField, TextInput, List } from 'react-admin';
import { Empty } from "../components/Empty";

const filters = [
  <TextInput label="Szukaj po nazwie" source="q" alwaysOn />
];

const AccountList = props => (
  <List exporter={false} filters={filters} empty={<Empty/>} bulkActionButtons={false} {...props}>
    <Datagrid rowClick="show">
      <TextField source="createdAt" label="Data dodania" />
      <TextField source="name" label="Nazwa" />
      <TextField source="legalForm" label="Forma prawna" />
      <TextField source="email" label="E-mail" />
      <TextField source="phone" label="Telefon" />
    </Datagrid>
  </List>
);

export default AccountList;