import React from "react";
import { UserMenu, MenuItemLink } from "react-admin";
import SettingsIcon from "@material-ui/icons/Settings";

const CustomMenu = (props) => (
  <UserMenu {...props}>
    <MenuItemLink
      to="/profile"
      primaryText="Zmiana hasła"
      leftIcon={<SettingsIcon />}
    />
  </UserMenu>
);

export default CustomMenu;