import * as React from "react";
import { Admin, Resource } from 'react-admin';
import { Route } from "react-router";

/**
 * Customized components
 */
import Layout from "./components/Layout";

/**
 * Data providers
 */
import dataProvider from "./providers/DataProvider";
import authProvider from "./providers/AuthProvider";
import i18nProvider from "./providers/TranslationProvider";

/**
 * Views
 */
import AccountList from "./views/AccountList";
import AddAccount from "./views/AddAccount";
import ShowAccount from "./views/ShowAccount";
import LoginPage from "./views/LoginPage";
import ProfileEdit from "./views/ProfileEdit";

/**
 * Theme
 */
import { lightTheme } from "./theme";
import AccountsIcon from '@material-ui/icons/People';

const initialState = {
  admin: { ui: { sidebarOpen: false, viewVersion: 0 } }
};

const App = () => (
  <Admin
      title="Panel partnerski"
      dataProvider={dataProvider}
      appLayout={Layout}
      customRoutes={[
        <Route
          key="profile"
          path="/profile"
          component={ProfileEdit}
        />
      ]}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      theme={lightTheme}
      initialState={initialState}
      loginPage={LoginPage}
      disableTelemetry
    >
    <Resource name="accounts" options={{ label: 'Kontrahenci' }} icon={AccountsIcon} list={AccountList} create={AddAccount} show={ShowAccount} />
  </Admin>
);


export default App;