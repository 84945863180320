import polyglotI18nProvider from 'ra-i18n-polyglot';
import pl from 'ra-language-polish';

let polishTranslation = pl;
pl.ra.page.create = "Dodawanie: %{name}";
pl.ra.page.list = "Lista: %{name}";
pl.ra.page.empty = "Brak wyników.";
pl.ra.page.invite = "Czy chcesz dodać nowy element?";

export default polyglotI18nProvider(locale => locale === "pl" ? polishTranslation : undefined, "pl");
