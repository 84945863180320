import * as React from "react";
import { TextField, Show, SimpleShowLayout } from 'react-admin';

const ShowAccount = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="name" label="Nazwa" />
      <TextField source="legalForm" label="Forma prawna" />
      <TextField source="email" label="E-mail" />
      <TextField source="phone" label="Telefon" />
      <TextField source="pesel" label="PESEL" />
      <TextField source="nip" label="NIP" />
      <TextField source="regon" label="REGON" />
      <TextField source="note" label="Uwagi" />
      <TextField source="createdAt" label="Utworzono" />
    </SimpleShowLayout>
  </Show>
);

export default ShowAccount;