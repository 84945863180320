import React from "react";

const BnpIcon = () => (
<svg version="1.1" id="Warstwa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="300px" height="300px"
	 viewBox="0 0 138 98">
<g>
	<g>
		<path d="M4.4,97.9H0V87h4.2c2.3,0,3.5,1.3,3.5,2.8c0,1.3-0.8,2.1-1.9,2.5C7,92.5,8,93.7,8,95C8,96.6,6.6,97.9,4.4,97.9z M4,88.5
			H1.8v3.1H4c1.2,0,1.9-0.5,1.9-1.6C5.9,89,5.2,88.5,4,88.5z M4.2,93.1H1.8v3.4h2.4c1.2,0,1.9-0.6,1.9-1.7
			C6.2,93.7,5.3,93.1,4.2,93.1z"/>
		<path d="M11.5,87h1.8v10.9h-1.8V87z"/>
		<path d="M24.1,88.4l-5.1,8h5.1V98h-7.2v-1.4l5.1-8h-5.1V87h7.2C24.1,87,24.1,88.4,24.1,88.4z"/>
		<path d="M36.7,87v10.9h-1.8l-5.4-8.2v8.2h-1.8V87h1.8l5.4,8.1V87H36.7z"/>
		<path d="M46.7,88.4h-4.2v3.2h3.8v1.5h-3.8v3.3h4.2v1.5h-6V87h6V88.4z"/>
		<path d="M54.1,98c-2.2,0-3.9-1.2-3.9-3.1h1.9c0.1,0.9,0.7,1.6,1.9,1.6c1.3,0,2-0.7,2-1.6c0-2.6-5.8-0.9-5.8-5
			c0-1.9,1.5-3.1,3.8-3.1c2.1,0,3.6,1.1,3.7,3h-2c-0.1-0.7-0.7-1.4-1.8-1.4c-1,0-1.8,0.5-1.8,1.5c0,2.5,5.8,0.9,5.8,4.9
			C57.9,96.5,56.5,98,54.1,98z"/>
		<path d="M76.3,87v10.9h-1.8l-5.4-8.2v8.2h-1.8V87h1.8l5.4,8.1V87H76.3z"/>
		<path d="M86.9,95.7h-4.6l-0.8,2.2h-1.9l4-10.9h2.1l3.9,10.9h-1.9L86.9,95.7z M84.6,89.2l-1.8,5.1h3.6L84.6,89.2z"/>
		<path d="M102.4,93.5h-2v4.4h-1.8V87h3.8c2.6,0,3.9,1.5,3.9,3.3C106.2,91.8,105.2,93.5,102.4,93.5z M102.4,92c1.4,0,2-0.7,2-1.8
			c0-1.1-0.6-1.8-2-1.8h-2V92H102.4z"/>
		<path d="M111.3,87v9.5h3.7v1.4h-5.5V87H111.3z"/>
		<path d="M118.1,87h1.8v7c0,1.7,1,2.5,2.5,2.5s2.5-0.8,2.5-2.5v-7h1.8v6.9c0,2.8-2,4.1-4.3,4.1s-4.2-1.3-4.2-4.1V87H118.1z"/>
		<path d="M134.2,98c-2.2,0-3.9-1.2-3.9-3.1h1.9c0.1,0.9,0.7,1.6,1.9,1.6c1.3,0,2-0.7,2-1.6c0-2.6-5.8-0.9-5.8-5
			c0-1.9,1.5-3.1,3.8-3.1c2.1,0,3.6,1.1,3.7,3h-2c-0.1-0.7-0.7-1.4-1.8-1.4c-1,0-1.8,0.5-1.8,1.5c0,2.5,5.8,0.9,5.8,4.9
			C138,96.5,136.7,98,134.2,98z"/>
	</g>
	<g fill="#CC9A2F">
		<rect x="102.6" y="34" width="4.6" height="4.6"/>
		<rect x="107.2" y="38.7" width="4.6" height="4.6"/>
		<rect x="107.2" y="29.4" width="4.6" height="4.6"/>
		<rect x="111.9" y="34" width="4.6" height="4.6"/>
		<path d="M47.3,4.6V68c1.2,0.7,2.5,1.3,3.8,1.8V2.7C49.8,3.3,48.6,3.9,47.3,4.6z"/>
		<path d="M58.3,36.5L71,18L59.1,0.5c-1.3,0.2-2.7,0.5-4,0.9L66.4,18L53.8,36.5l12.8,18.4L55.3,71.2
			c1.3,0.4,2.6,0.7,4,0.9L71.2,55L58.3,36.5z"/>
		<path d="M69,36.5L81.6,18l-12-17.7C68.1,0.1,66.5,0,64.9,0c0,0,0,0-0.1,0L77,18L64.4,36.5l12.8,18.4L65,72.5
			c1.6,0,3.2-0.1,4.8-0.3l12-17.3L69,36.5z"/>
		<path d="M79.6,36.5L92.3,18L83.6,5.2c-2.1-1.3-4.4-2.3-6.7-3.2l10.8,16L75,36.5l12.8,18.4L77.1,70.4
			c2.4-0.9,4.8-2,6.9-3.4l8.4-12.1L79.6,36.5z"/>
		<path d="M90.2,36.5l8.7-12.7c-0.5-1.4-1.1-2.8-1.8-4.1L85.6,36.5L97,52.9c0.7-1.3,1.3-2.6,1.8-4L90.2,36.5z"/>
		<path d="M39.9,10v52.5c1.2,1.1,2.4,2.2,3.8,3.2V6.9C42.4,7.9,41.1,8.9,39.9,10z"/>
		<path d="M32.5,20.1v32.3c1.1,2.1,2.3,4.2,3.8,6V14.1C34.8,16,33.6,18,32.5,20.1z"/>
	</g>
</g>
</svg>
);

export default BnpIcon;