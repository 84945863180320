import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { TextInput, SimpleForm, required, useDataProvider, useNotify, SaveContextProvider, minLength, PasswordInput, useGetIdentity, useLogoutIfAccessDenied } from 'react-admin';

export const ProfileEdit = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [saving, setSaving] = useState();

  const { loaded, identity } = useGetIdentity();
  const logout = useLogoutIfAccessDenied();

  const handleSave = useCallback((values) => {
    setSaving(true);
    dataProvider.create(
      "profile/password",
      {
        data: {
          username: values.username,
          current: values.current,
          new: values.new,
          new2: values.new2
        }
      },
      {
        onSuccess: () => {
          setSaving(false);
          notify("Hasło zostało zmienione", "info", {
            _: "Hasło zostało zmienione pomyślnie"
          });

          setTimeout(logout, 1500);
        },
        onFailure: () => {
          setSaving(false);
          notify("Nieprawidłowe dane", "warning", {
            _: "Hasło nie mogło zostać zmienione. Upewnij się, że dane są poprawne."
          });
        }
      }
    );
  }, [dataProvider, notify, logout]);

  useEffect(() => {
    if (loaded && !identity) {
      logout();
    }
  }, [loaded, identity, logout]);

  const saveContext = useMemo(() => ({
    save: handleSave,
    saving
  }), [saving, handleSave]);

  if (!loaded || !identity) {
    return null;
  }

  return (
    <SaveContextProvider value={saveContext}>
      <SimpleForm save={handleSave} record={{}}>
        <h1>Zmiana hasła</h1>
        <p>Aby zmienić hasło, proszę podać aktualną nazwę użytkownika, poprzednie hasło i nowe hasło.</p>
        <p>Nowe hasło musi zawierać co najmniej 8 znaków.</p>
        <TextInput source="username" label="Nazwa użytkownika" validate={required()} />
        <PasswordInput source="current" label="Aktualne hasło" validate={[required()]} />
        <PasswordInput source="new" label="Nowe hasło" validate={[required(), minLength(8, "Hasło jest za krótkie")]} />
        <PasswordInput source="new2" label="Nowe hasło (powtórz)" validate={[required(), minLength(8, "Hasło jest za krótkie")]} />
      </SimpleForm>
    </SaveContextProvider>
  );
};

export default ProfileEdit;