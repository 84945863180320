import { useEffect, useState } from "react";
import {
  SimpleForm,
  Create,
  
  /**
   * Hooks
   */
  useDataProvider,

  /**
   * Inputs
   */
  TextInput,
  FileInput,
  SelectInput,

  /**
   * Outputs
   */
  FileField,

  /**
   * Validators
   */
  required,
  email,
  regex
} from 'react-admin';

const AddAccount = props => {
  const [legalForms, setLegalForms] = useState([]);
  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider.getList('legal-forms', { pagination: {}, sort: {}, filter: {}}).then(
      ({ data }) => setLegalForms(data)
    );
  }, [dataProvider]);

  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" label="Nazwa" validate={[required()]} />
        <SelectInput source="legalForm" label="Forma prawna" validate={[required()]} choices={legalForms} />
        <TextInput source="pesel" label="PESEL" validate={[regex(/^[\d]{11}$/, "Podaj poprawny numer PESEL")]} />
        <TextInput source="nip" label="NIP" validate={[regex(/^[\d]{10}$/, "Podaj poprawny numer NIP")]} />
        <TextInput source="regon" label="REGON" validate={[regex(/^([\d]{9}|[\d]{14})$/, "Podaj poprawny numer REGON")]} />
        <TextInput source="email" label="E-mail" validate={[email()]} type="email" />
        <TextInput source="phone" label="Telefon" validate={[required(), regex(/^\+[\d]{11}$/, "Podaj pełen numer telefonu z kodem kraju (np. +48)")]} type="tel"/>
        <TextInput source="note" label="Uwagi" validate={[]} type="text" />
        <FileInput source="attachment" label="Załącznik" maxSize={10 * 1000000} accept="image/*,.pdf">
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
}

export default AddAccount;