import jsonServerProvider from "ra-data-json-server";
import { fetchUtils } from "ra-core";

export const API_URL = "/api";

function httpClient(url, options = {}) {
  /**
   * Apply authorization
   */
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }

  const auth = sessionStorage.getItem("auth");

  if (auth) {
    const token = JSON.parse(auth).token;

    options.headers.set('Authorization', `Bearer ${token}`);
  }

  return fetchUtils.fetchJson(url, options);
}

const dataProvider = jsonServerProvider(API_URL, httpClient);

/**
 * Handle file upload
 */
const blobToBase64 = blob => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  
  return new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

const jsonServerCreateAction = dataProvider.create;

dataProvider.create = (resource, params) => {
  if (params.data && 'attachment' in params.data) {
    const url = params.data.attachment.src;

    return fetch(url).then(
      content => content.blob()
    ).then(
      blobToBase64
    ).then(base64File => {
      params.data.attachment.content = base64File;

      return jsonServerCreateAction(resource, params);
    });
  }

  return jsonServerCreateAction(resource, params);
}

export default dataProvider;